.cafeHistoryModal {
  display: block;
  position: fixed;
  z-index: 66;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
}

.cafeHistoryModalContent {
  background-color: #861026;
  margin: 15% auto;
  padding: 20px;
  margin-top: 20%;
  border-radius: 5px;
  box-shadow: 0 0 5px black, 0 0 15px black, 0 0 25px rgba(0, 0, 0, 0.759);
  width: 80%;
  max-width: 1000px;
}

.cafeHistoryModalclose {
  color: #ceb16b;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.5s ease;
}

.cafeHistoryModalclose:hover,
.cafeHistoryModalclose:focus {
  transition: 0.5s ease;
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.cafeHistoryContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px;
}

.cafeHistoryTitle {
  font-size: 24px;
  font-weight: bold;
  font-family: "Croissant One", cursive;
  color: #ceb16b;
  text-shadow: 0 0 10px #000000;
  text-decoration: underline;
  margin-bottom: 20px;
}

.cafeHistoryContent {
  font-size: 18px;
  font-family: "Josefin Slab", serif;
  color: #ceb16b;
  text-shadow: 0 0 10px #000000;
  margin-bottom: 15px;
  line-height: 1.6;
}
