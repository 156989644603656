.aboutBgColor {
  background-color: rgba(0, 0, 0, 0.348);
}

.aboutContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-image: url("../images/AboutPage/bgAboutPage.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.aboutBlurOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.297);
  backdrop-filter: blur(10px);
  z-index: 1;
}

.aboutHeadingContainer {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.aboutHeading {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
}

.aboutTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ceb16b;
  text-shadow: 0 0 10px #000000;
  font-size: 90px;
  font-family: "Croissant One", cursive;
}

.aboutParagraph {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ceb16b;
  text-shadow: 0 0 10px #000000;
  font-size: 25px;
  font-family: "Josefin Slab", serif;
  margin-top: -25px;
}
.aboutContentContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  z-index: 1;

  gap: 20px;
}

.aboutContentFamilyContainer,
.aboutContentHistoryContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: -40px;
}

.aboutContentFamily,
.aboutContentHistory {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  background: linear-gradient(#9e001db2 50%, #660013b2 100%);
  border-radius: 5px;
  box-shadow: 0 0 5px black, 0 0 15px black, 0 0 25px rgba(0, 0, 0, 0.759);
}

.familyHistoryImgContainer,
.cafeHistoryImgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.familyHistoryImg,
.cafeHistoryImg {
  width: 30%;
  margin: 15px;
  border-radius: 5px;
  box-shadow: 0 0 5px black, 0 0 15px black, 0 0 20px #ceb16b;
  transition: 0.5s ease;
}
.familyHistoryImg:hover,
.cafeHistoryImg:hover {
  transition: 0.5s ease;
  box-shadow: 0 0 5px black, 0 0 15px black, 0 0 20px #ceb16b, 0 0 40px #ceb16b;
}

.aboutTitleFamily,
.aboutTitleCafe {
  font-family: "Croissant One", cursive;
  color: #ceb16b;
  text-shadow: 0 0 10px #000000;
  text-align: center;
}

.aboutHistoryContent {
  font-family: "Josefin Slab", serif;
  color: #ceb16b;
  margin-bottom: 15px;
  text-align: center;
  text-shadow: 0 0 10px #000000;
}
.scrollArrow {
  display: none;
}

@media screen and (max-width: 1370px) {
  .aboutContentContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    z-index: 1;
    margin-top: -20px;
    gap: 20px;
  }
  .aboutTitle {
    font-size: 80px;
  }
  .aboutParagraph {
    font-size: 20px;
  }
  .aboutContentFamily,
  .aboutContentHistory {
    width: 60%;
    padding: 20px;
  }
  .familyHistoryImg,
  .cafeHistoryImg {
    width: 30%;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px black, 0 0 15px black, 0 0 20px #ceb16b;
  }
}

@media screen and (max-width: 1200px) {
  .aboutHeadingContainer {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -20px;
  }
  .aboutContentContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    z-index: 1;
    margin-top: -4%;
    gap: 10px;
  }
  .aboutTitle {
    font-size: 60px;
  }
  .aboutParagraph {
    font-size: 18px;
    margin-top: -15px;
  }
  .aboutContentFamily,
  .aboutContentHistory {
    width: 60%;
    padding: 20px;
  }
  .aboutTitleFamily,
  .aboutTitleCafe {
    font-size: 20px;
    font-family: "Croissant One", cursive;
    color: #ceb16b;
    text-shadow: 0 0 10px #000000;
    text-align: center;
  }

  .familyHistoryImg,
  .cafeHistoryImg {
    width: 28%;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px black, 0 0 15px black, 0 0 20px #ceb16b;
  }
}
@media screen and (max-width: 550px) {
  .aboutTitle {
    font-size: 45px;
    margin-top: 25%;
  }
  .aboutParagraph {
    font-size: 12px;
    margin-top: -12px;
  }
  .aboutContentContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    overflow-x: auto;
    gap: 10%;
    padding: 10px;
    margin-top: -45%;
  }
  .aboutContentFamily,
  .aboutContentHistory {
    flex-shrink: 0;
    width: 300px;
    height: 50%;
    padding: 20px;
    margin-top: 20px;
  }
  .familyHistoryImg,
  .cafeHistoryImg {
    width: 60%;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px black, 0 0 15px black, 0 0 20px #ceb16b;
  }

  .scrollArrow {
    position: absolute;
    bottom: 15%;
    left: 0;
    background-color: transparent;

    border: none;
    border-radius: 50%;
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1;
  }
  .aboutArrowBtn {
    width: 35px;
    height: 35px;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: #ceb16b;
    font-size: 35px;
    box-shadow: 0 0 5px black, 0 0 10px #9e001db2;
    transition: 0.5s ease;
  }
  .aboutArrowBtn:hover {
    color: #ceb16b;
    font-size: 35px;
    box-shadow: inset 0 0 5px #9e001db2, 0 0 10px #9e001db2, 0 0 5px black,
      0 0 10px black, 0 0 20px #ceb16b;
  }
}
