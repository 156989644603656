.homeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-image: url("../images/HomePage/bgHomePage.png");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
}

.homeOverlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.261);
  z-index: 1;
}

.contentLeft {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  z-index: 2;
}

.contentContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 5%;
  margin-top: 15%;
}

.welcomeText {
  color: #ceb16b;
  font-size: 160px;
  font-family: "Croissant One", cursive;
  text-shadow: 0 0 10px black;
}

.welcomeTextSlogan {
  margin-top: -4%;
  color: #ceb16b;
  font-size: 50px;
  font-family: "Josefin Slab", serif;
  text-shadow: 0 0 10px black;
}

@media screen and (max-width: 1700px) {
  .welcomeText {
    font-size: 130px;
  }
  .welcomeTextSlogan {
    font-size: 40px;
  }
}

@media screen and (max-width: 1400px) {
  .welcomeText {
    font-size: 100px;
    margin-top: -10%;
    margin-left: 10%;
  }
  .welcomeTextSlogan {
    font-size: 30px;
    margin-left: 10%;
  }
}

@media screen and (max-width: 1300px) {
  .welcomeText {
    font-size: 100px;
    margin-top: -10%;
    margin-left: 20%;
  }
  .welcomeTextSlogan {
    font-size: 30px;
    margin-left: 20%;
  }
}

@media screen and (max-width: 1000px) {
  .homeContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-image: url("../images/HomePage/bgHomePageSmallScreen.png");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .contentContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 0%;
    margin-top: 0%;
  }
  .contentLeft {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .welcomeText {
    font-size: 100px;
    margin-top: 0%;
    margin-left: 0%;
  }
  .welcomeTextSlogan {
    font-size: 30px;
    margin-left: 0%;
  }
}

@media screen and (max-width: 900px) {
  .welcomeText {
    font-size: 80px;
  }
  .welcomeTextSlogan {
    font-size: 25px;
  }
}

@media screen and (max-width: 700px) {
  .welcomeText {
    font-size: 60px;
  }
  .welcomeTextSlogan {
    font-size: 25px;
  }
}

@media screen and (max-width: 550px) {
  .homeContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-image: url("../images/HomePage/bgHomePageSmallestScreen.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .welcomeText {
    font-size: 45px;
  }
  .welcomeTextSlogan {
    font-size: 23px;
    margin-bottom: 25%;
  }
}
