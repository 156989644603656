.headerContainer {
  position: relative;
  right: 0;
  width: 120px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 2rem;
  position: fixed;
  background: linear-gradient(
    to right,
    #9e001db2 50%,
    #660013b2 100%
  ); /*#9e001d8b*/
  z-index: 99;
  box-shadow: 0 0 5px black, 0 0 15px black, 0 0 25px rgba(0, 0, 0, 0.759);
}

.logo {
  color: #ceb16b;
  max-width: 150px;
  z-index: 2;
  position: absolute;
}

.navigationContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  bottom: 5%;
}

.navLinks {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  cursor: pointer;
}

a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-family: "Croissant One", cursive;
  text-transform: uppercase;
  color: #3c3c3c;
  margin-top: 3rem;
  text-decoration: none;
  transition: 0.5s;
}

.icon {
  z-index: 1;
}

span {
  font-size: 12px;
  z-index: 1;
}

a:hover {
  transition: 0.5s;
  color: #b59b5e;
}

.active {
  width: 60px;
  height: 55px;
  color: #ceb16b;
  background: #6d001481;
  text-shadow: 0 0 3px black;
  box-shadow: 0 0 5px black;
  border: 2px solid #ceb16b;
  border-radius: 5px;
  transform: scale(1.4);
}

.active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-image: url(../images/MenuTexture/menuTexture.png);
  background-size: cover;
}

@media screen and (max-width: 1000px) {
  .headerContainer {
    width: 70px;
    height: 100vh;
    padding: 0.3rem;
  }
  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    font-family: "Croissant One", cursive;
    text-transform: uppercase;
    color: #3c3c3c;
    margin-top: 1rem;
    text-decoration: none;
    transition: 0.5s;
  }
  .active {
    width: 60px;
    height: 50px;
    color: #ceb16b;
    background: #6d001481;
    text-shadow: 0 0 3px black;
    box-shadow: 0 0 5px black;
    border: 2px solid #ceb16b;
    border-radius: 5px;
    transform: scale(1.1) translateX(-40%);
  }
}

@media screen and (max-width: 600px) {
  .headerContainer {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    position: fixed;
    background: linear-gradient(
      to right,
      #9e001db2 50%,
      #660013b2 100%
    ); /*#9e001d8b*/
    z-index: 99;
    box-shadow: 0 0 15px black, 0 0 25px rgba(0, 0, 0, 0.759);
  }

  #navbar {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .logo {
    position: fixed;
    top: 5px;
    right: 10px;
  }
  .active {
    width: 60px;
    height: 50px;
    color: #ceb16b;
    background: #6d001481;
    text-shadow: 0 0 3px black;
    box-shadow: 0 0 5px black;
    border: 2px solid #ceb16b;
    border-radius: 5px;
    transform: scale(1.1) translateY(-40%);
  }
  span {
    font-size: 10px;
    z-index: 1;
  }
}
