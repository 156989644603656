.snap-parent {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}

.snap-child {
  height: 100vh;
  width: 100%;
  scroll-snap-align: start;
}
