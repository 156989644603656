.menuBgColor {
  background-color: rgba(0, 0, 0, 0.348);
}

.menuContainer {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("../images/MenuPage/bgMenuPage.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.menuContentContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuHeadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
}

.menuHeading {
  color: #ceb16b;
  text-shadow: 0 0 10px #000000;
  font-size: 80px;
  font-family: "Croissant One", cursive;
}

.menuBlurOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  z-index: 1;
}

.menuContentRight {
  z-index: 1;
  display: flex;
  justify-content: center;
  width: 50%;
}

.MenuBtn {
  width: 300px;
  height: 80px;
  border-radius: 5px;
  border: 3px solid #ceb16b;
  background-color: #861026d5;
  box-shadow: 0 0 5px black, inset 0 0 5px #b49a5e;
  transition: 0.5s ease;
  position: relative;
}

.MenuBtn:hover {
  transition: 0.5s ease;
  box-shadow: 0 0 10px black;
  transform: scale(1.1);
}

.menuBtnTexture {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/MenuTexture/menuTexture.png);
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.5;
}

.MenuBtnText {
  display: flex;
  justify-content: center;
  position: relative;
  font-size: 25px;
  font-family: "Croissant One", cursive;
  color: #ceb16b;
  text-shadow: 0 0 5px black;
}

.menuContentLeft {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
  width: 40%;
}

@media screen and (max-width: 1200px) {
  .MenuBtn {
    width: 250px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 1000px) {
  .menuContentContainer {
    flex-direction: column-reverse;
  }
  .menuHeading {
    display: none;
  }
  .MenuBtn {
    width: 100px;
    height: 60px;
    position: absolute;
    top: 10%;
  }
  .MenuBtnText {
    font-size: 18px;
  }
  .menuContentLeft {
    flex-direction: row;
    gap: 40%;
    width: 40%;
    margin-bottom: 10px;
    margin-right: 15%;
  }
  .menuContentRight {
    width: 100%;
  }
}
@media screen and (max-width: 750px) {
  .menuContentContainer {
    margin-bottom: 10%;
  }
  .menuHeading {
    display: none;
  }
  .MenuBtn {
    width: 70px;
    height: 45px;
  }
  .MenuBtnText {
    font-size: 15px;
  }
  .menuContentLeft {
    gap: 40%;
    margin-right: 20%;
  }
  .menuContentRight {
    width: 80%;
  }
}
@media screen and (max-width: 600px) {
  .menuContentContainer {
    margin-bottom: 10%;
  }
  .menuHeading {
    display: none;
  }
  .MenuBtn {
    width: 70px;
    height: 45px;
  }
  .MenuBtnText {
    font-size: 15px;
  }
  .menuContentLeft {
    gap: 40%;
    margin-right: 10%;
  }
  .menuContentRight {
    width: 80%;
  }
}
@media screen and (max-width: 550px) {
  .menuContentContainer {
    margin-bottom: 20%;
  }
  .menuHeading {
    display: none;
  }
  .MenuBtn {
    width: 55px;
    height: 45px;
  }
  .MenuBtnText {
    font-size: 12px;
  }
  .menuContentLeft {
    gap: 50%;
    margin-right: 5%;
  }
  .menuContentRight {
    width: 80%;
  }
}
@media screen and (max-width: 480px) {
  .menuContentLeft {
    gap: 50%;
    margin-right: 15%;
  }
}
