.galleryBgColor {
  background-color: rgba(0, 0, 0, 0.348);
}

.galleryContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-image: url("../images/GalleryPage/bgGalleryPage.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.galleryBlurOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.142);
  backdrop-filter: blur(10px);
  z-index: 1;
}

.galleryHeadingContainer {
  margin-top: 20px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
}

.galleryHeading {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.galleryTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ceb16b;
  text-shadow: 0 0 10px #000000;
  font-size: 100px;
  font-family: "Croissant One", cursive;
  z-index: 1;
}

.galleryParagraph {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ceb16b;
  text-shadow: 0 0 10px #000000;
  font-size: 25px;
  font-family: "Josefin Slab", serif;
  margin-top: -25px;
  margin-bottom: 25%;
  z-index: 1;
}

.instagramContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 650px;
  z-index: 2;
  margin-top: -3%;
}

@media screen and (max-width: 1200px) {
  .galleryTitle {
    font-size: 80px;
    margin-top: 5%;
  }
  .galleryParagraph {
    font-size: 20px;
  }
}

@media screen and (max-width: 750px) {
  .galleryTitle {
    font-size: 60px;
    margin-top: 15%;
  }
  .galleryParagraph {
    font-size: 16px;
    margin-top: -15px;
  }
}
@media screen and (max-width: 550px) {
  .galleryTitle {
    font-size: 45px;
    margin-top: 20%;
  }
  .galleryParagraph {
    font-size: 12px;
    margin-top: -10px;
  }
  .instagramContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 650px;
    z-index: 2;
    margin-top: -15%;
  }
}
