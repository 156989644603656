.fullMenuModal {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 3;
}

.fullMenuModalclose {
  color: #ceb16b;
  float: left;
  margin: 20px;
  font-size: 38px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.5s ease;
  position: fixed;
}

.fullMenuModalclose:hover,
.fullMenuModalclose:focus {
  transition: 0.5s ease;
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.fullMenuContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin: 20px;
  width: 100%;
  height: 100vh;
  margin-left: -2%;
  margin-top: 5%;
}

.menuPages {
  width: 55%;
  margin-bottom: 20px;
}

@media screen and (max-width: 1000px) {
  .menuPages {
    width: 80%;
  }
}

@media screen and (max-width: 750px) {
  .menuPages {
    width: 70%;
  }
}
@media screen and (max-width: 600px) {
  .fullMenuContainer {
    margin-left: 0%;
  }
  .menuPages {
    width: 80%;
  }
}
