.instagramfeed {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  z-index: 1;
}

.instaPostsContainer {
  position: relative;
  width: 200px;
  height: 200px;
  cursor: pointer;
}

.instaPosts {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  transition: 0.5s ease;
}
.instaPosts:hover {
  transition: 0.5s ease;
  box-shadow: 0 0 10px #9e001db2, 0 0 20px #ceb16b;
  transform: scale(1.1);
}

.instaModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.instaModalImage {
  max-width: 80%;
  max-height: 80%;
  border-radius: 5px;
  object-fit: contain;
  cursor: pointer;
  box-shadow: 0 0 10px #9e001db2, 0 0 20px #ceb16b;
}

@media screen and (max-width: 1200px) {
  .instagramfeed {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    margin-bottom: 5%;
  }
  .instaPostsContainer {
    width: 150px;
    height: 150px;
  }
}

@media screen and (max-width: 750px) {
  .instagramfeed {
    margin-bottom: 20%;
  }
  .instaPostsContainer {
    width: 130px;
    height: 130px;
  }
}
@media screen and (max-width: 550px) {
  .instagramfeed {
    margin-bottom: 50%;
    z-index: 1;
  }
  .instaPostsContainer {
    width: 110px;
    height: 110px;
  }
}
@media screen and (max-width: 380px) {
  .instagramfeed {
    margin-bottom: 50%;
  }
  .instaPostsContainer {
    width: 90px;
    height: 110px;
  }
}
