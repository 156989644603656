.drinkMenuContainer {
  width: 500px;
  max-width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #861026a7;
  border: 5px solid #ceb16b;
  border-radius: 5px;
  box-shadow: 0 0 5px black, inset 0 0 10px #b49a5e;
  position: relative;
}

.drinkMenuTexture {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(../images/MenuTexture/menuTexture.png);
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.4;
}

.drinkMenuHeading {
  text-align: center;
  font-size: 30px;
  font-family: "Croissant One", cursive;
  color: #ceb16b;
  text-shadow: 0 0 5px black;
  z-index: 1;
}

.drinkMenuParagraph {
  text-align: center;
  font-size: 18px;
  font-family: "Josefin Slab", serif;
  color: #ceb16b;
  text-shadow: 0 0 5px black;
  margin-bottom: 3%;
  z-index: 1;
}

.drinkMenu {
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  z-index: 1;
}

.drinkItems {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  font-size: 20px;
  font-family: "Josefin Slab", serif;
  color: #ceb16b;
  padding: 5px;
  border-bottom: 1px solid #ceb16b;
}

.drinkTitlePic {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.drinkPictures {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  box-shadow: 0 0 5px #ceb16b;
}

@media screen and (max-width: 750px) {
  .drinkMenuContainer {
    width: 400px;
    height: 450px;
  }
  .drinkItems {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 16px;
    padding: 5px;
    gap: 10px;
  }

  .drinkPictures {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    box-shadow: 0 0 5px #ceb16b;
  }
}
@media screen and (max-width: 570px) {
  .drinkMenuContainer {
    width: 300px;
    height: 380px;
  }
  .drinkMenuHeading {
    font-size: 20px;
    padding: 5px;
  }
  .drinkMenuParagraph {
    font-size: 14px;
  }
  .drinkMenu {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 10%;
  }
  .drinkItems {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: none;
    font-size: 14px;
  }
  .drinkPictures {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    box-shadow: 0 0 5px #ceb16b;
    transition: 0.5s ease;
  }
  .drinkPictures:hover {
    transform: scale(1.4);
    transition: 0.5s ease;
  }
}
