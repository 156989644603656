.eventsBgColor {
  background-color: rgba(0, 0, 0, 0.348);
}

.eventsContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-image: url("../images/EventsPage/bgAboutPage.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.eventsContentLeft {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.eventsContentRight {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin-left: 10%;
}

.eventsHeading {
  width: 500px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20%;
}

.eventsTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ceb16b;
  font-size: 100px;
  font-family: "Croissant One", cursive;
  z-index: 2;
  text-shadow: 0 0 10px #000000;
}

.eventsTitle1 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ceb16b;
  font-size: 90px;
  font-family: "Josefin Slab", serif;
  z-index: 2;
  margin-left: 10%;
  margin-top: -3%;
  text-shadow: 0 0 10px #000000;
}

.eventPicture {
  width: 450px;
  z-index: 1;
}

.blurOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
}

@media screen and (max-width: 1550px) {
  .eventsContentRight {
    width: 30%;
    margin-right: 10%;
  }
  .eventsContentLeft {
    margin-right: 5%;
  }
  .eventsTitle {
    font-size: 80px;
  }
  .eventsTitle1 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ceb16b;
    font-size: 70px;
    font-family: "Josefin Slab", serif;
    z-index: 2;
    margin-left: 10%;
    margin-top: -3%;
    text-shadow: 0 0 10px #000000;
  }
  .eventPicture {
    width: 400px;
    z-index: 1;
  }
}
@media screen and (max-width: 1300px) {
  .eventsTitle {
    font-size: 70px;
  }
  .eventsTitle1 {
    font-size: 60px;
  }
  .eventsContentLeft {
    margin-right: 0%;
    padding-left: 10%;
  }
  .eventsContentRight {
    width: 60%;
    margin-right: 10%;
  }
  .eventPicture {
    width: 100%;
    z-index: 1;
  }
}
@media screen and (max-width: 1000px) {
  .eventsContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-image: url("../images/EventsPage/bgAboutPage.jpg");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .eventsTitle {
    font-size: 60px;
  }
  .eventsTitle1 {
    font-size: 50px;
  }
  .eventsContentLeft {
    display: flex;
    align-items: center;
    height: 130px;
    margin-right: 0%;
    padding-left: 0%;
  }
  .eventsContentRight {
    width: 100%;
    margin-left: 10%;
  }
  .eventPicture {
    width: 60%;
    z-index: 1;
    margin-right: 20%;
  }
}
@media screen and (max-width: 750px) {
  .eventsContentRight {
    width: 100%;
  }
  .eventPicture {
    width: 60%;
    z-index: 1;
  }
}
@media screen and (max-width: 550px) {
  .eventsHeading {
    width: 300px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20%;
  }
  .eventsTitle {
    font-size: 45px;
    margin-top: 10%;
  }
  .eventsTitle1 {
    font-size: 35px;
    margin-top: -2%;
  }
  .eventPicture {
    width: 100%;
    z-index: 1;
    margin-left: 20%;
  }

  .eventsContentRight {
    width: 220px;
    margin-bottom: 25%;
    margin-left: 0%;
  }
}
