.menuFrontPageContainer {
  width: 500px;
  max-width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #861026d5;
  border: 5px solid #ceb16b;
  border-radius: 5px;
  box-shadow: 0 0 5px black, inset 0 0 10px #b49a5e;
  position: relative;
}

.menuTexture {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(../images/MenuTexture/menuTexture.png);
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.5;
}

.menuFrontPageHeading {
  font-size: 85px;
  font-family: "Croissant One", cursive;
  color: #ceb16b;
  text-shadow: 0 0 5px black;
  z-index: 1;
}

.menuFrontPageSlogan {
  font-size: 25px;
  font-family: "Croissant One", cursive;
  color: #ceb16b;
  text-shadow: 0 0 5px black;
  margin-bottom: 10%;
  z-index: 1;
}
@media screen and (max-width: 700px) {
  .menuFrontPageContainer {
    width: 350px;
    height: 500px;
  }
  .menuFrontPageHeading {
    font-size: 55px;
  }
  .menuFrontPageSlogan {
    font-size: 18px;
  }
}
@media screen and (max-width: 550px) {
  .menuFrontPageContainer {
    width: 300px;
    height: 400px;

    margin-bottom: 3%;
  }
  .menuFrontPageHeading {
    font-size: 55px;
  }
  .menuFrontPageSlogan {
    font-size: 18px;
  }
}
